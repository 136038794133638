import { withBlitz } from "app/blitz-client"
import App, { AppContext } from "next/app"
import Head from "next/head"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from "@blitzjs/next"
import { AuthenticationError, AuthorizationError } from "blitz"
import { Suspense, useEffect } from "react"
import { Provider } from "react-redux"
import { MetaTag } from "@Types/common"
import store from "app/core/redux/store"
import "app/core/styles/index.css"
import "app/core/styles/antd-overrides.css"
import SocketMessageEventListener from "app/sockets/SocketMessageEventListener"
import useThemeFavicon from "@Hooks/useThemeFavicon"
import { SocketContextProvider } from "app/sockets/SocketContext"
import ToastProvider from "@Components/ui/Toast/ToastProvider"
import ToastHost from "@Components/ui/Toast/ToastHost"
import ModalPortalHost from "@Components/ui/Modal/ModalPortalHost"
import ModalPortalProvider from "@Components/ui/Modal/ModalPortalProvider"
import {
  getIsFubEmbeddedApp,
  applyFubFetchInterceptor,
  applyFubClientSession,
} from "app/fubAuth/clientUtils"
import { isFubSubdomain } from "app/fubAuth/commonUtils"
import { logError } from "integrations/sentry"

if (typeof window !== "undefined") {
  applyFubFetchInterceptor()
  applyFubClientSession()
  require("@ant-design/v5-patch-for-react-19")
}

function MyApp({ Component, pageProps: _pageProps }: AppProps) {
  useThemeFavicon()

  const getLayout = Component.getLayout || ((page) => page)
  const pageProps = _pageProps as any
  const metaTags = pageProps?.metaTags || []
  const isFubEmbeddedApp = pageProps?.isFubEmbeddedApp || false

  useEffect(() => {
    if (isFubEmbeddedApp) {
      document.body.classList.add("fub-slim-ui")
    } else {
      document.body.classList.remove("fub-slim-ui")
    }
    return () => {
      document.body.classList.remove("fub-slim-ui")
    }
  }, [isFubEmbeddedApp])

  const page = pageProps?._internalError ? (
    <ErrorComponent
      statusCode={pageProps._internalError?.statusCode}
      title={pageProps._internalError?.message}
    />
  ) : (
    getLayout(<Component {...pageProps} />)
  )

  const { reset } = useQueryErrorResetBoundary()

  return (
    <Provider store={store}>
      <Head>
        {metaTags.map((metaTagProps: MetaTag[], index: number) => (
          <meta key={index} {...metaTagProps} />
        ))}
      </Head>
      <ErrorBoundary FallbackComponent={RootErrorFallback} onReset={reset}>
        <Suspense fallback={""}>
          <ToastProvider>
            <ModalPortalProvider>
              <SocketContextProvider>
                <SocketMessageEventListener />
                {page}
              </SocketContextProvider>
              <ModalPortalHost />
              <ToastHost />
            </ModalPortalProvider>
          </ToastProvider>
        </Suspense>
      </ErrorBoundary>
    </Provider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const host = appContext.ctx.req?.headers.host
  const isFubEmbeddedApp = isFubSubdomain(host || "")

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      isFubEmbeddedApp,
    },
  }
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    const isFubEmbeddedApp = getIsFubEmbeddedApp()
    if (isFubEmbeddedApp) {
      return (
        <ErrorComponent
          statusCode={error.statusCode}
          title="Sorry, you are not authorized to access this"
        />
      )
    }
    const message = encodeURIComponent("Sorry, you'll need to log in again.")
    window.location.href = `/login?authError=${message}`
    return null
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    logError(error, "Error from [Error Boundary]")

    return (
      <ErrorComponent
        statusCode={error?.statusCode || 500}
        title={`[Error Boundary] ${error.message || error.name}`}
      />
    )
  }
}

export default withBlitz(MyApp)
